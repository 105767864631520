import React, {Component} from "react";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import SSLogo from "../../assets/logos/ss-logo-full-white.png";
import { withRouter } from '../wrappers/withRouter';
import AutocompleteSongSearchAll from "../AutocompleteSongSearchAll";
import { withAuth0 } from "@auth0/auth0-react";
import NavigationIconList from "../NavigationIconList";


class AppHeader extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            // <ThemeProvider theme={appHeaderTheme}>
            <Box 
				position="sticky"
				top="0"
				zIndex={1000}
				sx={{
					// flexGrow: 1,
					paddingRight: 4,
					paddingLeft: 4,
					paddingTop: 4,
					marginTop: 4,
					marginBottom: 2,
					backgroundColor: "#121212",
				}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box textAlign={"center"}>
                            <Box
                                component="img"
                                sx={{
                                    height: 70,
                                    width: 200,
                                    mr: 2,
                                    flexgrow: 1,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    }
                                }}
                                alt="Sounds and Sights Logo White"
                                src={SSLogo}
                                onClick={() => this.props.router.navigate(`/app`)}
                            />
                        </Box>
                    </Grid>
                {/*<Toolbar sx={{ justifyContent: "space-between"}}>*/}
                    <Grid item xs={12} sm={12} md={6}>
                        <Box marginTop={1}>
                            <AutocompleteSongSearchAll />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}
						sx={{
							[`@media only screen and (max-width: 700px)`]: {
								display: 'none',
							},
						}}>
                        <NavigationIconList />
                    </Grid>
                </Grid>
            </Box>
            // </ThemeProvider>
        )
    }
}

export default withAuth0(withRouter(AppHeader));