import './App.css';
import { Routes, Route } from "react-router-dom";
import SongsAppContainer from './components/pages/SongsAppContainer';
import SongDetails from './components/pages/SongDetailsList';
import ProjectList from "./components/pages/ProjectList";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ProjectCreate from './components/ProjectCreate';
import MoodboardEdit from './components/MoodboardEdit';
import MoodboardCreate from './components/MoodboardCreate'
import ProjectDetail from './components/ProjectDetail';
import SongEdit from './components/SongEdit';
import SyncFiles from './components/pages/SyncFiles';
import LandingPage from "./components/pages/LandingPage";
import ProfilePage from './components/pages/ProfilePage';
import { withAuth0 } from '@auth0/auth0-react';
import PaymentCheckoutPage from './components/pages/PaymentCheckoutPage';
import { UserService } from './Services/UserService';
import { Component } from 'react';
import AuthService from './Services/AuthService';
import {withRouter} from "./components/wrappers/withRouter";
import { Navigate } from 'react-router-dom';
import { DefaultLoadingScreen } from './components/DefaultLoadingScreen';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionStatus: null,
      isLoading: true,
	  isBackendError: false,
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props.auth0;

    if (isAuthenticated) {
      console.log("not authed", this.state);
      // Fetch subscription status if user is authenticated
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated, isLoading } = this.props.auth0;

    if (isAuthenticated && !isLoading && prevProps.auth0.isLoading) {
      // Fetch subscription status if user is authenticated and auth0 is done loading
      this.fetchData();
    }
  }

  async fetchData() {
    try {
      // we have a user in auth0 but in our database yet
      let token = await AuthService.getTokenSilently(this.props.auth0);

      // get or create the user in our own system
      let user = await UserService.create({}, token);

      this.setState({
        subscriptionStatus: user.subscription ? user.subscription.status : null,
        isUserRecordCreated: !!user,
        isLoading: false,
      });
    } catch (error) {
		console.error("Error fetching subscription status:", error);
		this.setState({ 
			isLoading: false,
			isBackendError: true
		});
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth0;
    const { subscriptionStatus, isLoading, isUserRecordCreated } = this.state;

	if(this.state.isBackendError) {
		console.log("state - backend error", this.state);
		return  <LandingPage />;
	}

    if (!isAuthenticated) {
		console.log("state - not authed", this.state);
		return (
			<Routes>
				<Route path="/" element={<LandingPage />}></Route>
				<Route path="/app/*" element={<LandingPage />}></Route>
				<Route path="*" element={<div>Page Not Found</div>} />
			</Routes>
		);
    }

    if (isLoading) {
		console.log("state - loading", this.state);
		return <DefaultLoadingScreen />;
    }

    if (subscriptionStatus !== "TRIAL" && subscriptionStatus !== "ACTIVE" && subscriptionStatus !== "PENDING_CANCELLATION") {
		console.log("state - subscription not in acceptable state", this.state);

		// https://chatgpt.com/c/677c4231-f9c4-8004-85f3-fe9427e6f36c
		if (window.location.pathname !== '/app/payments/checkout') {
			this.props.router.navigate(`/app/payments/checkout`);
			return null; // Avoid rendering anything during the redirect
		}
		return <PaymentCheckoutPage />;
    }

    console.log("state - all good", this.state);
    return (
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/app" element={<SongsAppContainer />}></Route>
        <Route path="/app/songs/:songTitlePretty" element={<SongDetails key={window.location.pathname} />}></Route>
        <Route path="/app/songs/edit/:songTitlePretty" element={<SongEdit />}></Route>
        <Route path="/app/sync" element={<SyncFiles />}></Route>
        <Route path="/app/project/:id" element={<ProjectDetail />}></Route>
        <Route path="/app/project/new" element={<ProjectCreate />}></Route>
        <Route path="/app/project/list" element={<ProjectList />}></Route>
        <Route path="/app/moodboard/:moodboardId" element={<MoodboardEdit />}></Route>
        <Route path="/app/moodboard/create" element={<MoodboardCreate />}></Route>
        <Route path="/app/profile" element={<ProfilePage />}></Route>
        <Route path="/app/payment/return" element={ <Navigate to="/app" /> }></Route>
        <Route path="/app/payments/checkout" element={ <Navigate to="/app" /> }></Route>
        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
    );
  }
}

export default withRouter(withAuth0(App));