import { Component } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import cardBackground from '../../assets/card-images/default-add-song-grey.png'
import SongIdeaFirstSongFileDropZone from "../file-dropzones/SongIdeaFirstSongFileDropZone";


/**
 * A React component that renders a card with a dropzone for song drafts (no bounce added yet).
 * 
 * @extends Component
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.songTargetId - The ID of the target song.
 * 
 * @example
 * <SongIdeaFileDropzoneCard songTargetId="12345" />
 */
export class SongIdeaFileDropzoneCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card 
                sx={{
					minHeight: 125,
                    borderRadius: '10px',
                    [`@media only screen and (max-width: 700px)`]: {
                        display: 'none',
                    },
                }}>
                <CardContent>
                    <SongIdeaFirstSongFileDropZone songTargetId={this.props.songTargetId}/>
                </CardContent>
            </Card>
        )
    }
}