import React, {Component} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box} from "@mui/material";
import SSLogo from "../../assets/logos/ss-logo-full-black.png";
import SignUpButton from "../buttons/SignUpButton";
import LoginButton from "../buttons/LoginButton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AutocompleteSongSearchAll from "../AutocompleteSongSearchAll";

export class LandingPageHeader extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Box sx={{
                flexGrow: 1,
                paddingRight: 2,
                paddingLeft: 2,
                paddingTop: 4,
                marginBottom: 2
            }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5}>
                    <Box textAlign={"center"}>
                        <Box
                            component="img"
                            sx={{
                                height: 70,
                                width: 200,
                                mr: 2,
                                flexgrow: 1,
                                // '&:hover': {
                                //     cursor: 'pointer'
                                // }
                            }}
                            alt="Sounds and Sights Logo White"
                            src={SSLogo}
                            // onClick={() => this.props.router.navigate(`/app`)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={0} sm={12} md={2}>

                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box flexGrow={1}>
                        <Stack direction="row" spacing={2} sx={{
                            ['@media only screen and (min-width: 1000px)']: {
                                justifyContent: 'flex-end',
                            },
                            ['@media only screen and (min-width: 0px)']: {
                                justifyContent: 'center',
                            },
                        }}>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            </Box>
        )
    }
}