import React, {Component} from "react";
import NavigationIconList from "../NavigationIconList";
import { Box } from "@mui/material";

class AppFooter extends Component {
	constructor() {
		super();
	}
	
	render() {
		return (
			<Box
				position="sticky"
				bottom="0"
				sx={{
					[`@media only screen and (min-width: 700px)`]: {
						display: 'none',
					},
				}}
				bgcolor="background.paper"
				p={1}
				borderTop="1px solid #ccc"
				zIndex={1000}>
				<NavigationIconList />
			</Box>
		)
	}
}

export default AppFooter;