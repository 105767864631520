import React from "react";
import { withRouter } from './wrappers/withRouter';
import LowPriorityIcon from "../assets/icons/svg-dot-grey.svg";
import RegularPriorityIcon from "../assets/icons/svg-dot-blue.svg";
import HighPriorityIcon from "../assets/icons/svg-dot-yellow.svg";
import HighestPriorityIcon from "../assets/icons/svg-dot-red.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import DefaultDraftArtwork from '../assets/card-images/default-draft-album-art-square-2.png';
import DefaultSongArtwork from '../assets/card-images/default-song-album-art-square.png';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { rootUrl } from "../Utilities/Utils";
import AuthService from "../Services/AuthService";
import { withAuth0 } from '@auth0/auth0-react';
import { SongService } from "../Services/SongService";
import { FileService } from "../Services/FileService";
import { DateUtilities } from "../Utilities/DateUtilities";

class SongsTableCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: true,
            songs: [],
            isPlayingAudio: false,
            currentSongPlaying: null,
        };
        this.audio = null;
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const url = `${rootUrl}/songs?limit=100&sort=DESC&skip=0&sortBy=versionHistory`;
        const token = await AuthService.getTokenSilently(this.props.auth0);
        const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
        });
        const songs = await response.json();

        await Promise.all(songs.map(async (song) => {
            if (song.versionHistory && song.versionHistory.length > 0) {
                song.songAudioFile = await this.getAudioFile(song.versionHistory[0].fileName);
            }
        }));

        this.setState({ songs, isLoadingData: false });
    }

    async getAudioFile(fileName) {
        const token = await AuthService.getTokenSilently(this.props.auth0);
        const presignedUrl = await FileService.findOneById(fileName, token);
        return presignedUrl.signedURL;
    }

    handleSongTitleClick(song) {
		const isDraft = !song.versionHistory || song.versionHistory.length === 0;

		if(isDraft) {
			return;
		}

		this.pauseAudio();

        if (this.state.currentSongPlaying === song.songTitlePretty) {
            this.setState({ isPlayingAudio: false, currentSongPlaying: null });
        } else {
            this.audio = new Audio(song.songAudioFile);
            this.audio.play();
            this.audio.onended = () => this.setState({ isPlayingAudio: false, currentSongPlaying: null });
            this.setState({ isPlayingAudio: true, currentSongPlaying: song.songTitlePretty });
        }
    }

	pauseAudio() {
		if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
        }
	}

    handleExpand(song) {
		this.pauseAudio();

        this.props.router.navigate(`/app/songs/${song.songTitlePretty}/`);
    }

	renderRow(song) {
		const isDraft = !song.versionHistory || song.versionHistory.length === 0;
        const artwork = isDraft
            ? DefaultDraftArtwork
            : song.primaryArtwork
                ? `${rootUrl}/media/${song.primaryArtwork.fileName}`
                : DefaultSongArtwork;

		let lastUpdatedTime = '';
		if(isDraft) {
			lastUpdatedTime = DateUtilities.getDateDiffInDays(new Date(song.createdDate), new Date())
		} else {
			// lastUpdatedTime = DateUtilities.getDateDiffInDays(new Date(song.versionHistory[0].createdTime), new Date())
			lastUpdatedTime = DateUtilities.getDateDiffInDays(new Date(Math.max(...song.versionHistory.map(vh => new Date(vh.createdTime).getTime()))), new Date())
		}
	
		return (
			<Box>
				<Grid 
				container 
				spacing={2} 
				alignItems="center" 
				key={song.id} 
				sx={{ 
					padding: 1,
					// borderBottom: "1px solid rgb(42, 42, 42)",
					flexWrap: "nowrap" // Ensures the row stays on one line
				}}>
				{/* Fixed size for album art */}
				<Grid item sx={{ flex: '0 0 50px' }}>
					<img src={artwork} alt="Artwork" style={{
						borderRadius: '8px',
						width: '50px', 
						height: '50px', 
						objectFit: 'cover'
					}} />
				</Grid>
				{/* Flexible column for song details */}
				<Grid item sx={{ 
					flex: '1 1 auto', 
					overflow: 'hidden',
					}}>
					<Typography
						onClick={() => this.handleSongTitleClick(song)}
						sx={{
							cursor: 'pointer',
							color: this.state.currentSongPlaying === song.songTitlePretty ? "#00FF00" : "#eee",
							whiteSpace: 'nowrap',      // Prevents wrapping to the next line
							overflow: 'hidden',       // Hides overflowing content
							textOverflow: 'ellipsis', // Adds ellipsis when content is truncated
							// "&:hover": {
							// 	// border: '1px solid',
							// 	// borderColor: "#FFFFFF",
							// 	transition: '0.3s',
							// 	fontWeight: 'bold'
							// 	// boxShadow: '5px 7px 11px 1px #00FF00',
							// },
						}}
					>
						{song.songTitlePretty}
					</Typography>
					<Typography variant="body2" color="textSecondary">
						{`${song.project?.name || 'No project'} • ${lastUpdatedTime} Days ago`}
					</Typography>
				</Grid>
				{/* Fixed size for expand icon */}
				<Grid item sx={{ flex: '0 0 100px', textAlign: 'center' }}>
					<OpenInNewIcon onClick={() => this.handleExpand(song)} sx={{ cursor: 'pointer', color: 'rgb(110, 110, 110)' }} />
				</Grid>
			</Grid>
			</Box>
		);
	}

    render() {
        if (this.state.isLoadingData) {
            return <CircularProgress />;
        }

        return (
            <Box sx={{ maxWidth: 700, margin: "auto" }}>
                {this.state.songs.map(song => this.renderRow(song))}
            </Box>
        );
    }
}

export default withAuth0(withRouter(SongsTableCustom));
