import React, {Component} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import HomeIcon from "@mui/icons-material/Home";
import AlbumIcon from "@mui/icons-material/Album";
import Stack from "@mui/material/Stack";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AuthService from "../Services/AuthService";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box } from "@mui/material";
import { withRouter } from './wrappers/withRouter';
import { withAuth0 } from '@auth0/auth0-react';

class NavigationIconList extends Component {

	handleHomeNavClick(e) {
        e.preventDefault();

        this.props.router.navigate(`/app`);
    }

    handleProjectNavClick(e) {
        e.preventDefault();

        this.props.router.navigate(`/app/project/list/`);
    }

    handleSynchronizeButton(e){
        e.preventDefault();

        this.props.router.navigate(`/app/sync`);
    }

    handleProfileButton(e) {
        e.preventDefault();

        this.props.router.navigate(`/app/profile`);
    }


	render() {
		return (
			<Box>
			<List component={Stack} direction="row">
				<ListItem
					disablePadding
					key={"Home"}
					sx={{
						flexDirection: "column"
					}}>
					<ListItemButton
						onClick={(e) => this.handleHomeNavClick(e)}
						sx={{
							justifyContent: "center",
						}}>
						<HomeIcon />
					</ListItemButton>
					<Box sx={{
						fontSize: 10,
					}}>
						Home
					</Box>
				</ListItem>
				<ListItem
					key={"Projects"}
					sx={{
						flexDirection: "column"
					}}
					disablePadding>
					<ListItemButton
						onClick={(e) => this.handleProjectNavClick(e)}
						sx={{
							justifyContent: "center",
						}}>
						<AlbumIcon />
					</ListItemButton>
					<Box sx={{
						fontSize: 10,
					}}>
						Projects
					</Box>
				</ListItem>
				{/* <ListItem
					key={"Synchronize"}
					disablePadding
					sx={{
						flexDirection: "column"
					}}>
					<ListItemButton
						onClick={(e) => this.handleSynchronizeButton(e)}
						sx={{
							justifyContent: "center",
						}}>
						<CloudUploadIcon />
					</ListItemButton>
					<Box sx={{
						fontSize: 10,
					}}>
						Upload
					</Box>
				</ListItem> */}
				<ListItem
					key={"Profile"}
					disablePadding
					sx={{
						flexDirection: "column"
					}}>
					<ListItemButton
						onClick={(e) => this.handleProfileButton(e)}
						sx={{
							justifyContent: "center",
						}}>
						<AccountBoxIcon />
					</ListItemButton>
					<Box sx={{
						fontSize: 10,
					}}>
						Profile
					</Box>
				</ListItem>
				{/* <ListItem key={"Log Out"}
						sx={{
							flexDirection: "column"
						}}
						disablePadding>
					<ListItemButton
						onClick={() => AuthService.logout(this.props.auth0)}
						sx={{
							justifyContent: "center",
						}}>
						<MeetingRoomIcon />
					</ListItemButton>
					<Box sx={{
						fontSize: 10,
					}}>
						Log Out
					</Box>
				</ListItem> */}
			</List>
		</Box>
		);
	}
}

export default withAuth0(withRouter(NavigationIconList));