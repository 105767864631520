import * as React from 'react';
import { withRouter } from '../wrappers/withRouter';
import SongDetail from '../SongDetail';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";
import SongLyricsCard from '../cards/SongLyricsCard'
import SongEdit from '../SongEdit';
import AuthService from "../../Services/AuthService";
import { SongService } from "../../Services/SongService";
import { ProjectService } from "../../Services/ProjectService";
import { withAuth0 } from "@auth0/auth0-react";
import AppHeader from "../headers/AppHeader";
import { ThemeProvider } from "@mui/material/styles";
import { darktheme } from "../../Utilities/MUIThemes";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { DefaultLoadingScreen } from "../DefaultLoadingScreen";
import { SongIdeaFileDropzoneCard } from "../cards/SongIdeaFileDropzoneCard";
import { FileService } from "../../Services/FileService";
import Recorder from "../Recorder";
import AppFooter from "../footers/AppFooter";
import { DateUtilities } from '../../Utilities/DateUtilities';


const fabStyle = {
    position: "fixed",
    bottom: 40,
    right: 40,
};

class SongDetailsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            songTitlePretty: this.props.router.params.songTitlePretty,
            isLoadingData: true,
            songDetails: {},
            latestVersionNotes: '',
            projects: [],
            albumArtworkForUpload: {}
        }
    }

    componentDidMount() {
        console.log("AUTH", this.props.auth0.isAuthenticated, this.props.auth0.isLoading);
        this.fetchData();
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let songData = await SongService.findOneById(this.state.songTitlePretty, token);

        let projectList = await ProjectService.findAll(token);

        let presignedUrlForAlbumArt;

        if(songData.primaryArtwork) {
            presignedUrlForAlbumArt = await FileService.findOneById(songData.primaryArtwork.fileName, token);
        }

		// @TODO NICK if this become too much of a bottleneck, grab these in parallel
		// and have some other isLoadingVideo state that conditionally renders the videos - or just
		// move all this video loading / display logic into a separate component.
		// 
		// probably separating out**
		if(songData.moodboard && songData.moodboard.videoReferences) {
			await Promise.all(songData.moodboard.videoReferences.map(async (video) => {
				let videoUrl = await FileService.findOneById(video.fileName, token);
				video.signedURL = videoUrl.signedURL;
			}));
		}

        this.setState({
            projects: projectList,
            songDetails: songData,
            isLoadingData: false,
            albumArtURL: presignedUrlForAlbumArt
        });
    }

    handleMoodboardCreate() {
        this.fetchData();
    }

    /**
     * update function to pass down to children so that if they make updates, we can refresh our stuff.
     */
    async onUpdateSongTitlePretty(e, newSongTitlePretty) {
        e.preventDefault();
        console.log("Song Details list: onUpdateSongTitlePretty", newSongTitlePretty);
        this.setState({
            songTitlePretty: newSongTitlePretty
        }, async () => {
            console.log(this.state);
            await this.fetchData();
            this.forceUpdate();
        })
    }

    getAlbumArtHeaderImage() {
        return this.state.songDetails.primaryArtwork ? this.state.albumArtURL.signedURL : '';
    }

    getSongDetailsCoverView() {
        if(this.props.auth0.isAuthenticated) {
            if(this.state.songDetails.primaryArtwork) {
                return (
					<Grid item xs={12} sm={12}>
						<Card sx={{ minWidth: 325, minHeight: 350 }}>
							<CardContent>
								<CardMedia
									image={this.getAlbumArtHeaderImage()}
									title="song artwork"
									sx={{
										height: 150
									}}>
								</CardMedia>
								<Typography variant={"h1"} align={"center"}>
									{this.state.songTitlePretty}
								</Typography>
								<Typography variant={"h5"} align={"center"}><Link to="/app">Home</Link></Typography>
							</CardContent>
						</Card>
					</Grid>
                )
            } else {
                return (
                        <Grid item xs={12} sm={12} marginBottom={2}
                        marginTop={2}>
                            <Typography variant={"h3"} align={"center"}>
                                {this.state.songTitlePretty}
                            </Typography>
                        </Grid>
                )
            }
        } else if (!this.props.auth0.isAuthenticated && this.props.auth0.isLoading) {
            return (
                <DefaultLoadingScreen />
            )
        }
    }

    /**
     * @TODO NICK - add to the dropzone functionality so that we can keep this here
     * and it can add new song files to the current song directly
     * 
     * only show the dropzone if we have version history
     * @returns {JSX.Element}
     */
    getDropzoneView() {
        if(this.state.songDetails.versionHistory.length == 0) {
            return (
                <Grid item xs={12} md={6}>
                    <SongIdeaFileDropzoneCard songTargetId={this.state.songDetails.songTitlePretty} />
                </Grid>
            )
        }
    }

    getMoodBoardView() {
        if(!this.state.isLoadingData && this.state.songDetails.moodboard && this.state.songDetails.moodboard.videoReferences.length > 0) {
            return (
				<Grid container
					spacing={4}
					alignContent={"center"}
					direction="row"
					>
					{this.state.songDetails.moodboard.videoReferences.map((video) => {

						let videoTitleLastUpdated = DateUtilities.getDateDiffInDays(new Date(video.createdDate), new Date())

						return (
							<Grid item xs={6} md={4}>
								<Card>
									<CardMedia>
										<video key={video.signedURL} controls style={{ width: '100%' }} >
											<source src={video.signedURL}></source>
											Your browser does not support the video tag.
										</video>

									</CardMedia>
									<CardContent>
										<Typography variant="h5">{videoTitleLastUpdated} Days Ago</Typography>
										<Typography variant="body2" color="textSecondary">
											Transcription Feature Coming Soon
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						)
					})}
				</Grid>
            )
        }
    }

	newVideoAddedFromRecorderCallback() {
		this.fetchData();
	}

    render() {
        if(!this.state.isLoadingData) {
            return (
                <ThemeProvider theme={darktheme}>
                    <CssBaseline />
                    <Container>
                        <AppHeader />
                        <Grid
                            container
                            spacing={2}>

                            {this.getSongDetailsCoverView()}

							<Grid item xs={12} md={12}>
								<Recorder songTitlePretty={this.state.songTitlePretty} newVideoAddedFromRecorderCallback={() => this.newVideoAddedFromRecorderCallback()}></Recorder>
							</Grid>
                        </Grid>

                        <Box sx={{
                            display: 'flex',
                            paddingTop: 2
                        }}>
							<Grid container
								spacing={4}
								alignContent={"center"}
								direction="row">
								{this.state.songDetails.versionHistory
									.slice(0, 2) // assuming the version history is sorted by version, just grab the first two
									.map((version, index) => {
										return (
											<Grid item xs={12} md={6}>
												<SongDetail key={version.id} version={version} />
											</Grid>
										);
								})}
								
								<Grid item xs={12} md={this.state.songDetails.versionHistory.length > 0 ? 6 : 12}> 
									{/* if we have version history, make this half screen with 
								other stuff, otherwise make it full screen in draft mode */}
									<SongLyricsCard song={this.state.songDetails}/>
								</Grid>

								<Grid item xs={12} md={12}>
									{this.getMoodBoardView()}
								</Grid>

								{this.getDropzoneView()}

								<Grid item xs={12} md={6}>
									<SongEdit
										songTitlePretty={this.state.songDetails.songTitlePretty}
										// if we're editing song fields that matter to us here, use this callback to let us know
										onUpdateSongTitlePretty={(e, newSongTitlePretty) => this.onUpdateSongTitlePretty(e, newSongTitlePretty)}
									/>
								</Grid>
							</Grid>
                        </Box>
						<AppFooter></AppFooter>
                    </Container>
                </ThemeProvider>
            )
        } else {
            return (
                <DefaultLoadingScreen />
            )
        }

    }
}

export default withAuth0(withRouter(SongDetailsList));

/*
	<Grid item xs={12} md={6}>
		<Card sx={{
			// minWidth: 325,
			minHeight: 50,
			borderRadius: '10px'
		}}>
			<CardContent sx={{
				'&:hover': {
					background: 'rgb(2,0,36)',
					background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
				}
			}}>
				<CardMedia
					sx={{
						cursor: 'pointer',
						borderRadius: '5px',
					}}
					// image={this.getImageForCard()}
					title="default song artwork"
					onClick={() => this.createNewSong()}
					>
					<Box
						textAlign={"center"}
					>
						<Box>
							<FileUploadIcon />
						</Box>
					</Box>
					<Typography variant={"body1"} color={"secondary"} textAlign={"center"}>
						upload inspo
					</Typography>
				</CardMedia>
			</CardContent>
		</Card>
	</Grid>
*/