import * as React from "react";
import ProjectCard from "../cards/ProjectCard";
import { Link } from "react-router-dom";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Grid from "@mui/material/Grid";
import { withRouter } from '../wrappers/withRouter';
import {ProjectService} from "../../Services/ProjectService";
import AuthService from "../../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import {darktheme} from "../../Utilities/MUIThemes";
import CssBaseline from "@mui/material/CssBaseline";
import AppHeader from "../headers/AppHeader";
import {ThemeProvider} from "@mui/material/styles";
import {Container} from "@mui/material";
import {DefaultLoadingScreen} from "../DefaultLoadingScreen";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppFooter from "../footers/AppFooter";
import StartNewProjectCard from "../cards/StartNewProjectCard";


const fabStyle = {
    position: 'absolute',
    bottom: 40,
    right: 40,
};

class ProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            isLoadingData: true
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let results = await ProjectService.findAll(token);

        this.setState({
            projects: results,
            isLoadingData: false
        });
    }

    getProjectGridView() {
        if(this.state.projects.length > 0){
            return (
				<Box>
					<Grid container
						spacing={4}
						direction='row'>
						{this.state.projects.map((project) => {
							return (
								<Grid item xs={12} sm={12} md={6} lg={4}>
									<ProjectCard name={project.name}/>
								</Grid>
							)
						})}
					</Grid>
				</Box>
            )
        } else {
            return (
                <Box sx={{
                    display: 'flex'
                }}>
                    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5}}>
                        <Box textAlign={"center"}>
							<Box textAlign={'center'}>
								<Typography variant='h1'>
									Projects 💿
								</Typography>
								<br/>
								<Typography variant='h2'>
									Albums, EPs, Playlists, and more.
								</Typography>
								<br />
								<Typography variant='body1'>
									Your songs are better together. Create a new project, then add songs to it <br />
									Group by vibe, BPM, sad vibes, whatever you want.<br />
								</Typography>
								<br/>
							</Box>
                            <br/>
                            <Typography variant="body1">
                                <Link onClick={(e) => this.handleNewProjectPageLinkClick(e)}>Create</Link> a new project.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )
        }
    }

    handleNewProjectPageLinkClick(e) {
        e.preventDefault();

        this.props.router.navigate(`/app/project/new`);
    }

    /*
        <Box textAlign={"center"}>
              <Typography variant="h3">
                Your journey starts here.
              </Typography>
              <br/>
              <Typography variant="body1">
                Hit the <Link onClick={(e) => this.handleUploadPageLinkClick(e)}>Upload Page</Link> to start uploading
                songs.
              </Typography>
     */

    render() {
        if(!this.state.isLoadingData) {
            return (
                <ThemeProvider theme={darktheme}>
					<CssBaseline />
					<Container
						maxWidth='xl'
						sx={{
							// backgroundColor: darktheme.palette.primary.main,
							minHeight: '100vh',
							display: 'flex',
							flexDirection: 'column',
							paddingRight: 1,
							paddingLeft: 1
						}}
					>
                        <AppHeader />

						<Box
			  				flexGrow={1}>
							<Box component='main'
								sx={{
									[`@media only screen and (min-width: 100px)`]: {
										marginTop: 0,
									},
									[`@media only screen and (min-width: 700px)`]: {
										marginTop: 2,
								}}}>

								<StartNewProjectCard />
							
								{this.getProjectGridView()}
							</Box>
						</Box>
						<AppFooter></AppFooter>
                    </Container>
                </ThemeProvider>
            )
        } else {
            return <DefaultLoadingScreen />
        }
    }
}

export default withAuth0(withRouter(ProjectList));