import { Component } from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import * as React from "react";
import newSongIcon from '../../assets/icons/ss-new-song-icon.png'
import Box from "@mui/material/Box";
import { withAuth0 } from "@auth0/auth0-react";
import {withRouter} from "../wrappers/withRouter";

class StartNewProjectCard extends Component {
    constructor(props) {
        super(props)
    }

    fetchData() {}

    /**
     * go to the new project page
	 * @TODO NICK - proactively create a project and just let you edit the details after the fact
     * @returns {Promise<void>}
     */
    async handleClick() {
        this.props.router.navigate(`/app/project/new`);
    }

    getImageForCard() {}

    render() {
        return (
            <Card sx={{
                minWidth: 100,
                minHeight: 50,
                borderRadius: '10px',
				marginTop: 1
            }}>
                <CardContent sx={{
					'&:hover': {
						background: 'linear-gradient(90deg, rgba(152,70,252,1) 0%, rgba(251,135,63,1) 100%)',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					},
				}}>
                    <CardMedia
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '5px',
							[`@media only screen and (min-width: 100px)`]: {
								height: 75,
							},
							[`@media only screen and (min-width: 700px)`]: {
								height: 125,
							},
                        }}
                        // image={this.getImageForCard()}
                        title="default song artwork"
                        onClick={() => this.handleClick()}
                        >
                        <Box
                            textAlign={"center"}
                        >
                            <Box sx={{
								[`@media only screen and (min-width: 100px)`]: {
									paddingTop: 0,
								},
								[`@media only screen and (min-width: 700px)`]: {
									paddingTop: 3,
								},
							}}>
                                <img
                                    src={newSongIcon}
                                    style={{
                                        width: 50,
                                        height: 50,
                                    }}
                                ></img>
                            </Box>
                        </Box>
                        <Typography variant={"body1"} color={"secondary"} textAlign={"center"}>
                            create new project
                        </Typography>
                    </CardMedia>
                </CardContent>
            </Card>
        )
    }
}

export default withRouter(withAuth0(StartNewProjectCard));