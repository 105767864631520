import React, { Component } from 'react';
import { Card, CardContent, CardMedia, Box, Typography, Grid } from '@mui/material';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import { IconButton } from '@mui/material';
import { FileService } from "../Services/FileService";
import { SongService } from '../Services/SongService';
import AuthService from '../Services/AuthService';
import { withAuth0 } from '@auth0/auth0-react';

class Recorder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecording: false,
            recordedBlob: null,
            errorMessage: '',
            audioLevel: 'green', // Indicator for audio level: 'green' or 'red'
            isCameraOn: false, // Tracks whether the camera feed is active
        };

        this.mediaRecorder = null;
        this.stream = null;
        this.audioContext = null;
        this.analyser = null;
        this.audioInput = null;
    }

    turnOnCamera = async () => {
        try {
            // Request audio/video stream
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user" } });

            // Show the video preview
            const videoElement = document.querySelector('#videoPreview');
            videoElement.srcObject = this.stream;
            videoElement.play();

			// Mute the audio in the preview
			videoElement.muted = true;

            // Set up audio feedback (optional, since recording hasn't started yet)
            this.setupAudioFeedback(this.stream);

            this.setState({ isCameraOn: true });
        } catch (error) {
            this.setState({ errorMessage: 'Error accessing media devices: ' + error.message });
        }
    };

	getVideoTypeByBrowser() {
		const userAgent = navigator.userAgent;
	
		if (/Chrome/.test(userAgent)) {
			return 'video/webm'; // Chrome
		} else if (/Safari/.test(userAgent)) {
			return 'video/mp4'; // Safari
		}
		return 'video/webm'; // Default to WebM if undetected
	}

	startRecording = () => {
		if (!this.stream) return;

		let recordingTimeout;
	
		try {
			const mimeType = this.getVideoTypeByBrowser();

			if (!mimeType) {
				throw new Error('Neither MP4 nor WebM formats are supported in this browser');
			}
	
			// Create MediaRecorder instance
			this.mediaRecorder = new MediaRecorder(this.stream, { mimeType });
	
			const chunks = [];
			this.mediaRecorder.ondataavailable = (event) => {
				chunks.push(event.data);
			};

			this.mediaRecorder.onstart = () => {
				// Set a new timeout for 30 seconds
				recordingTimeout = setTimeout(() => {
					if (this.mediaRecorder.state === 'recording') {
						this.mediaRecorder.stop();
						console.log('Recording stopped automatically after 30 seconds.');
					}
				}, 30000); // 30 seconds in milliseconds
			}

			this.mediaRecorder.onstop = () => {
				clearTimeout(recordingTimeout);

				this.stopStream(); // Stop the camera feed
				// Combine chunks into a Blob
				const blob = new Blob(chunks, { type: mimeType });

				this.setState({ 
					recordedBlob: blob, 
					recordedMimeType: mimeType,
					isRecording: false
				}, this.handleMediaFinishedRecording);
			};
	
			this.mediaRecorder.start(); // Start recording
			this.setState({ isRecording: true });
		} catch (error) {
			this.setState({ errorMessage: 'Error starting recording: ' + error.message });
		}
	};

	/**
	 * handle the media recording being finished
	 * 	- upload the recording to the server
	 */
	async handleMediaFinishedRecording() {

		let token = await AuthService.getTokenSilently(this.props.auth0);

		let song = await SongService.findOneById(this.props.songTitlePretty, token);

		let moodboardId = song.moodboard ? song.moodboard.id : undefined;

		if(!moodboardId) {
			throw new Error('no moodboard associated with this song to save the recording to');
		}

		let blob = this.state.recordedBlob;

		const extension = this.state.recordedMimeType === 'video/mp4' ? 'mp4' : 'webm';

		const timestamp = new Date().toISOString().replace(/[:-]/g, '').split('.')[0]; // Format: YYYYMMDDTHHMMSS

		let fileName = `recording-${timestamp}.${extension}`;

		let file = new File([blob], fileName, { type: this.state.recordedMimeType });

		// @TODO NICK figure out if I need to create some sort 
		// of sharing mechanism here since direct download to camera roll 
		// isn't possible with browser apps
		// const filesArray = [file];
		// if (navigator.canShare && navigator.canShare({ files: filesArray })) {
		// 	navigator.share({
		// 		files: filesArray,
		// 		title: 'My Recording',
		// 		text: 'Save your recording!',
		// 	})
		// 	.catch(console.error);
		// } else {
		// 	console.log('Sharing is not supported on this browser.');
		// }

		// upload the file to the files service
		let data = new FormData();
		data.append('file', file);
		data.append('fileName', fileName);
		data.append('createdTime', new Date());
		data.append('moodboardId', moodboardId);

		let uploadResult = await FileService.create(data, token);

		// refresh data
		this.props.newVideoAddedFromRecorderCallback();
	}

    stopRecording = () => {
        if (this.mediaRecorder) {
            this.mediaRecorder.stop(); // Stop recording
        }
        this.setState({ isRecording: false });
    };

    stopStream = () => {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
        this.setState({ isCameraOn: false });
    };

    setupAudioFeedback = (stream) => {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.audioInput = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.audioInput.connect(this.analyser);

        const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        const checkAudioLevel = () => {
            this.analyser.getByteFrequencyData(dataArray);
            const volume = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;

            // Set audio level indicator (simple green/red logic)
            this.setState({ audioLevel: volume > 50 ? 'green' : 'red' });

            if (this.state.isCameraOn) {
                requestAnimationFrame(checkAudioLevel);
            }
        };

        checkAudioLevel();
    };

    stopAudioFeedback = () => {
        if (this.audioContext) {
            this.audioContext.close();
        }
    };

    downloadRecording = () => {
        const { recordedBlob } = this.state;
        if (recordedBlob) {
            const url = URL.createObjectURL(recordedBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `recording.${this.getVideoTypeByBrowser()}`;
            a.click();
        }
    };

    render() {

        const { isRecording, recordedBlob, errorMessage, audioLevel, isCameraOn } = this.state;

        return (
            <Box>
                {/* Turn on/off Camera */}
                {/* <button onClick={isCameraOn ? this.stopStream : this.turnOnCamera}>
                    {isCameraOn ? 'Turn Off Camera' : 'Turn On Camera'}
                </button> */}

				<Card sx={{
						// minWidth: 325,
						// minHeight: 50,
						// borderRadius: '10px'
					}}>
					<CardContent sx={{
						'&:hover': {
							background: 'rgb(63,94,251)',
							background: 'radial-gradient(circle, rgba(17,87,233,1) 0%, rgba(70,224,215,1) 50%, rgba(52,255,0,1) 100%)',
						},
					}}>
						<CardMedia
							sx={{
								cursor: 'pointer',
								borderRadius: '5px',
								// [`@media only screen and (min-width: 100px)`]: {
								// 	height: 75,
								// },
								// [`@media only screen and (min-width: 700px)`]: {
								// 	height: 125,
								// },
							}}
							// image={this.getImageForCard()}
							title="default song artwork"
							onClick={isCameraOn ? () => this.stopStream() : () => this.turnOnCamera()}
							>
							<Box
								textAlign={"center"}
							>
								<Box>
									{/* <img
										src={newSongIcon}
										style={{
											width: 50,
											height: 50,
										}}
									></img> */}
									<CameraEnhanceIcon />
								</Box>
							</Box>
							<Typography variant={"body1"} color={"secondary"} textAlign={"center"}>
								{isCameraOn ? 'Turn Off Idea Recorder' : 'Turn on Idea Recorder'}
							</Typography>
						</CardMedia>
					</CardContent>
				</Card>

                {/* Camera Preview */}
                <Grid item xs={12}>
				<Box position="relative">
    				{/* Camera Preview */}
					<video
						id="videoPreview"
						muted
						playsInline
						style={{
							width: '100%',
							border: '1px solid black',
							paddingTop: '16px',
							display: isCameraOn ? 'block' : 'none',
						}}
					></video>

					{/* Record Button */}
					{isCameraOn && (
						<Box
							position="absolute"
							bottom="16px"
							left="45%"
							transform="translateX(-50%)"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<IconButton
								onClick={isRecording ? this.stopRecording : this.startRecording}
								sx={{
									background: isRecording ? 'rgba(255, 0, 0, 1)' : 'radial-gradient(circle, rgba(52,255,0,1) 0%, rgba(70,224,215,1) 50%, rgba(17,87,233,1) 100%)',
									border: '4px solid rgba(0, 0, 0, 1)',
									width: 60,
									height: 60,
									boxShadow: 3,
									backgroundSize: isRecording ? 'auto' : '200% 200%',
									'&:hover': {
										border: '4px solid rgba(255, 255, 255, 1)',
									},
									'@keyframes gradientAnimation': {
										'0%': { backgroundPosition: '10% 0%' },
										'50%': { backgroundPosition: '91% 100%' },
										'100%': { backgroundPosition: '10% 0%' },
									},
									animation: isRecording ? 'none' : 'gradientAnimation 3s ease infinite', // Smooth infinite animation
								}}
							>
								{/* <RadioButtonCheckedIcon fontSize="large" /> */}
							</IconButton>
						</Box>
					)}
				</Box>
				</Grid>

                {/* Audio Level Indicator */}
                {/* {isCameraOn && (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: audioLevel,
                            borderRadius: '50%',
                            margin: '10px',
                        }}
                    ></div>
                )} */}

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </Box>
        );
    }
}

export default withAuth0(Recorder);

/*
 // Recorded Video Preview
	{recordedBlob && (
		<div>
			<video src={URL.createObjectURL(recordedBlob)} controls 
			/>
			<button onClick={this.downloadRecording}>Download</button>
			</div>
		)}
 */