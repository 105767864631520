import * as React from "react";
import { withRouter } from './wrappers/withRouter';
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Card from "@mui/material/Card";
import { SongService } from "../Services/SongService";
import Grid from '@mui/material/Grid';
import {ProjectService} from "../Services/ProjectService";
import AuthService from "../Services/AuthService";
import {withAuth0} from "@auth0/auth0-react";
import authService from "../Services/AuthService";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";


class SongEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            songTitlePretty: this.props.songTitlePretty || this.props.router.params.songTitlePretty,
            songTitlePrettyForUpdate: this.props.songTitlePretty || this.props.router.params.songTitlePretty,
            lyrics: '',
            song: {},
            projects: [],
            isLoadingData: true,

            // song update field state
            isSongTitlePrettyTextInputError: false,
            songTitlePrettyTextInputErrorReason: ''
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    /**
     * fetch everything we need for the component
     * @returns {Promise<void>}
     */
    async fetchData() {
        let token = await authService.getTokenSilently(this.props.auth0);

        /*
            we're using the "update" song identifier here because in the scenario that we need to fetch data
            but we've just changed the unique identifier that the page is using to fetch, if we use the OG
            then it won't be found.

            but in the case where we haven't updated, this "update" field will just be a copy of the original
            so it should always have the value that we're looking for here.
         */
        let songData = await SongService.findOneById(this.state.songTitlePrettyForUpdate, token);
        let projectList = await ProjectService.findAll(token);

        this.setState({
            projects: projectList,
            song: songData,
            isLoadingData: false
        });
    }

    /**
     * update the project the song belongs to
     * @param e
     * @returns {Promise<void>}
     */
    async handleProjectUpdate(e) {
        e.preventDefault();

        this.setState({
            isLoadingData: true
        }, async () => {
            let token = await AuthService.getTokenSilently(this.props.auth0);

            let project = await SongService.update({
                project: e.target.value,
                id: this.state.song.songTitlePretty
            }, token);

            await this.fetchData();

            this.setState({
                isLoadingData: false
            });
        });
    }

    /**
     * update the song identifier
     * @param e
     */
    async handleSongNameSubmit(e) {
        e.preventDefault();

        // validate one more time - if it's invalid, do nothing
        if(this.state.isSongTitlePrettyTextInputError) {
            return;
        }

        this.setState({
            isLoadingData: true
        }, async () => {
            let token = await AuthService.getTokenSilently(this.props.auth0);

            let params = {
                songTitlePretty: this.state.songTitlePrettyForUpdate, // updated song identifier
                id: this.state.song.songTitlePretty // previous song identifier that we're using to find the record
            };

            let result = await SongService.update(params, token);

            if(result.error) {
                console.error("ERROR!", result.error);
                this.setState({
                    isLoadingData: false,
                    isSongTitlePrettyTextInputError: true,
                    songTitlePrettyTextInputErrorReason: result.error
                });
            } else {
                await this.fetchData();

                this.setState({
                    isLoadingData: false,
                    songTitlePretty: this.state.songTitlePrettyForUpdate,
                }, async () => {
                    this.props.router.navigate(`/app/songs/${this.state.songTitlePrettyForUpdate}`);

                    this.forceUpdate();

                    // our parents migth want to know about what we're doing.
                    if(this.props.onUpdateSongTitlePretty) {
                        await this.props.onUpdateSongTitlePretty(e, this.state.songTitlePrettyForUpdate);
                    }
                });
            }
        });
    }

    /**
     * @TODO NICK implement
     * @param e
     * @returns {Promise<void>}
     */
    async handleSongNameUpdate(e) {
        e.preventDefault();

        let validationPayload = this.validateSongName(e.target.value);

        if(!validationPayload.error) {
            // validation pass
            // set the internal state to our new text value
            this.setState({
                songTitlePrettyForUpdate: e.target.value,
                isSongTitlePrettyTextInputError: false,
                songTitlePrettyTextInputErrorReason: ''
            });
        } else {
            // validation fail
            // set our text eror state
            this.setState({
                isSongTitlePrettyTextInputError: true,
                songTitlePrettyTextInputErrorReason: validationPayload.errorReason,
            });
        }
    }

    /**
     * validation for song file names
     *
     * @TODO NICK make this configurable
     * @param songName
     * @returns {boolean}
     */
    validateSongName(songName) {
        console.log("validateSongName called with song name", songName);
        let isValidResult = {
            error: false,
            errorReason: ''
        }

        // too short
        if(songName.length === 0) {
            isValidResult.error = true;
            isValidResult.errorReason = `Song title can't be empty`;
        }
        // too long
        if(songName.length > 60) {
            isValidResult.error = true;
            isValidResult.errorReason = `Song title can't be more than 60 characters`;
        }

        return isValidResult;
    }

    async handlePriorityUpdate(e) {
        e.preventDefault();

        this.setState({
            isLoadingData: true
        }, async () => {
            let token = await AuthService.getTokenSilently(this.props.auth0);

            let params = {
                priority: e.target.value,
                id: this.state.song.songTitlePretty
            };

            let results = await SongService.update(params, token);

            console.log("handle priority update results", results);

            await this.fetchData();

            this.setState({
                isLoadingData: false
            });
        });
    }

    render() {
        if(!this.state.isLoadingData) {
            return (
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl sx={{m: 1, minWidth: 250}}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Current Project
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={this.state.song.project && this.state.song.project.id ? this.state.song.project.id : "-"}
                                        inputProps={{
                                            name: 'age',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange={(e) => this.handleProjectUpdate(e)}
                                    >
                                        <option value={'-'}>-</option>
                                        {this.state.projects.map((project) => {
                                            return (
                                                <option value={project.id}>{project.name}</option>
                                            );
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{m: 1, minWidth: 120}}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Priority
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={this.state.song.priority}
                                        inputProps={{
                                            name: 'priority',
                                            id: 'priority-select',
                                        }}
                                        onChange={(e) => this.handlePriorityUpdate(e)}
                                    >
                                        <option value="0">Low</option>
                                        <option value="1">Regular</option>
                                        <option value="2">High</option>
                                        <option value="3">Highest</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} margin={1}>
                                <form onSubmit={(e) => this.handleSongNameSubmit(e)}>
                                    <TextField
                                        id="standard-basic"
                                        error={this.state.isSongTitlePrettyTextInputError}
                                        helperText={this.state.songTitlePrettyTextInputErrorReason}
                                        label="Song Title"
                                        fullWidth={true}
                                        variant="standard"
                                        defaultValue={this.state.songTitlePretty}
                                        onChange={(e) => this.handleSongNameUpdate(e)}
                                        sx={{
                                            marginBottom: '15px'
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            "&:hover": {
                                                border: '2px solid',
                                                borderColor: "#FFFFFF",
                                                // boxShadow: '5px 7px 11px 1px #00FF00',
                                            }}}
                                        >
                                        Submit Changes
                                    </Button>
                                </form>
                            </Grid>
                        </Grid>

                        {/* <br/>
                        <br/>

                        <br/>
                        <br/>
                        <br/>
                        <br/> */}
                        {/*Stage: {STAGE_VALUES[this.state.song.stage.stageName]}*/}
                    </CardContent>
                </Card>
            )
        } else {
            return (
                <CircularProgress />
            );
        }
    }
}

export default withAuth0(withRouter(SongEdit));

// /**
//  * update the album art state internally
//  * @param e
//  */
// handleAlbumArtUpdate(e) {
//     e.preventDefault();
//     console.log(e);
//
//     this.setState({
//         currentAlbumArtworkForUpload: {
//             fileName: e.target.files[0].name,
//             file: e.target.files[0]
//         }
//     });
// }

// /**
//  * update the album art associated with this song
//  * @param e
//  * @returns {Promise<void>}
//  */
// async handleAlbumArtSubmit(e) {
//     e.preventDefault();
//
//     let data = new FormData();
//     data.append('file', this.state.currentAlbumArtworkForUpload.file)
//     data.append('fileName', this.state.currentAlbumArtworkForUpload.fileName);
//
//     let file = await FileService.create(data);
//
//     let song = await SongService.update({
//         id: this.state.songTitlePretty,
//         primaryArtworkId: file.id
//     });
//
//     this.setState({
//         albumArtworkForUpload: {}
//     }, this.fetchData);
// }


/*

<Grid item xs={6}>
                               <form onSubmit={(e) => this.handleAlbumArtSubmit(e)}>
                                   <input
                            		id="icon-button-photo"
                                        onChange={(e) => this.handleAlbumArtUpdate(e)}
                                        type="file"
                                    />
                                    <Button type={"submit"}>Submit</Button>
                                </form>
                            </Grid>
							*/