export const projectTypeMap = {
    0: "EP",
    1: "Album",
    2: "Single",
    3: "Playlist"
}

export const rootUrl = process.env.NODE_ENV === 'production' ? 'https://api.sounds-sights.com' : 'https://localhost:4001';

export const Auth0Options = {
    development: {
        domain: "dev-w857yjgssfpi1imk.us.auth0.com",
        clientId: "8DyN0ERTHdZKOxT0LW0w534t7hCrCIJc",
        authorizationParams: {
            audience: `https://127.0.0.1:4001`,
            redirect_uri: 'https://127.0.0.1:3000/app',
            scope: 'openid profile email offline_access'
        }
    },
    production: {
        domain: "sounds-sights.us.auth0.com",
        clientId: "3yRweYldNwn0VkF40wA3hZnl6c4Hz2Nu",
        authorizationParams: {
            audience: `https://api.sounds-sights.com`,
            redirect_uri: 'https://sounds-sights.com/app',
            scope: 'openid profile email offline_access'
        }
    }
}

export const STAGE_VALUES = {
    0: "0 - Pending Initial Eval",
    1: "1 - Initial Idea Phase",
    2: "2 - Sound Design",
    3: "3 - Revise Lyrics and Flow",
    4: "4 - Releveling / Rough Mix",
    5: "5 - Transitions / Ear Candy",
    6: "6- Record Final Vox",
    7: "7 - Add Automation",
    8: "8- Final Production Lightning Round",
    9: "9 - Mixing",
    10: "10 - Mastering",
    11: "11 - Submit for Release",
    12: "12 - Released"
}

export const getFileExtension = (fileName) => {
    return fileName.split('.')[fileName.split('.').length - 1];
}