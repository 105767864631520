import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton';
import DefaultProjectArtwork from "../../assets/card-images/default-project-image.jpg";
import CardMedia from "@mui/material/CardMedia";
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import {withRouter} from "../wrappers/withRouter";
import {rootUrl} from "../../Utilities/Utils";
import {ProjectService} from "../../Services/ProjectService";
import AuthService from "../../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";


class ProjectCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            project: {},
            isLoadingData: true
        }
    }

    projectTypeMap = {
        0: "EP",
        1: "Album",
        2: "Single"
    }

    componentDidMount() {
        this.fetchData()
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let results = await ProjectService.findOneById(this.state.name, token);

        this.setState({
            project: results ? results[0] : {},
            isLoadingData: false
        });
    }

    getStyle() {
        return {
            "borderStyle": "solid"
        }
    }

    handleCardImageClick(e) {
        e.preventDefault();

        // @TODO IMPLEMENT
        // this.props.router.navigate(`/app/project/${this.state.name}/`);
    }

    render() {
        if(!this.state.isLoadingData) {
            return (
                    <Card sx={{
						minWidth: 325, 
						minHeight: 345, 
						maxHeight: 345,
						borderRadius: '15px',
						border: '2px solid',
						borderColor: "#121212",
						marginTop: 2,
						"&:hover": {
							border: '2px solid',
							borderColor: "#FFFFFF"
						}
					}}>
                        <CardContent>
                            <CardMedia
                                sx={{
                                    height: 90,
									borderRadius: '5px',
                                    // @TODO IMPELEMENT - when the detail view is ready for projects
                                    // cursor: 'pointer'
                            }}
                                image={DefaultProjectArtwork}
                                title="default song artwork"
                                onClick={(e) => {this.handleCardImageClick(e)}}>
                                <QueueMusicIcon sx={{margin: "10px"}}/>
                            </CardMedia>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Project
                            </Typography>
                            <Typography variant="h5" component="div">
                                {this.state.project.name}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Project Type: {this.projectTypeMap[this.state.project.type]}
                            </Typography>
                            <Typography variant="body2">
                                Tracklist:
                                <br />
                                {this.state.project.songs.map((song) => {
                                    return (
                                        <div>{song.songTitlePretty}</div>
                                    )
                                })}
                            </Typography>
                        </CardContent>
                    </Card>
            )
        } else {
            return <CircularProgress />
        }
    }
}

export default withAuth0(withRouter(ProjectCard));